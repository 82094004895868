import { TutorialsWrap, TutorialsDisclaimer, TutorialLink, TutorialDescription, TutorialName, Content } from './styles';
import { Link } from '../../styles';
import DashboardSection from '../WidgetBase/DashboardSection';
import { ITutorialsWidget } from './types';
import { WidgetData } from '../../types';
import { Icon } from '@common/components/Button/styles';

const TutorialsWidget = (widgetData: WidgetData) => {
  const { tutorialItems } = widgetData.data as ITutorialsWidget;

  return (
    <DashboardSection title="Tutorials">
      <TutorialsWrap>
        {tutorialItems?.map((x) => {
          return (
            <TutorialLink key={x.name} onClick={() => window.open(x.link, '_blank')}>
              <Icon className="tick" src="/icons/gray_tick.svg" />
              <Content>
                <TutorialName>{x.name}</TutorialName>
                <TutorialDescription>{x.description}</TutorialDescription>
              </Content>
              <Icon className="arrowPostfix" src="/icons/arrow_right.svg" height="15px" />
            </TutorialLink>
          );
        })}
      </TutorialsWrap>
      <TutorialsDisclaimer>
        For more help and tutorials visit the{' '}
        <Link target="_blank" href={process.env.REACT_APP_HELP_URL}>
          emc Academy
        </Link>
      </TutorialsDisclaimer>
    </DashboardSection>
  );
};

export default TutorialsWidget;
