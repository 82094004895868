import { abortUpload } from '@api/uploadFileWithProgression';
import { ICompanyConfigurationDto } from '@common/features/configuration/types';
import { useAddDocument } from '@common/features/document/components/DocumentContentDetails/hooks/useAddDocument';
import {
  getCanUpload,
  getDocumentAttributes,
  getDocumentProcessingFlowForDocument,
  getIsFileValid,
} from '@common/features/document/helpers';
import { useAddSecondaryDocumentOnModal } from '@common/features/document/hooks/useAddSecondaryFile';
import { AddModalOpenModes, FileRoles, FormFields } from '@common/features/document/types';
import { IDocumentDto, IProductDto } from '@common/features/submission/types';
import { useChangeDocumentAssociation } from '@common/features/submissionTask/hooks/useChangeDocumentAssociation';
import { removeHttpPrefixFromLink } from '@common/helpers';
import { DocumentType, ICheckboxEntry, Id } from '@common/types';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { useUploadFile } from '@hooks/useUploadFile';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { useCallback, useMemo, useState } from 'react';
import { usePrepareFileUploadForm } from '../usePrepareForm/usePrepareFileUploadForm';
import { useHandleInitialConversionResultForDocument } from '@common/hooks/useFetchDocumentConversionResults';

interface IProps {
  contentType: DocumentType;
  submissionId?: string;
  productGroupId: number | null;
  products: IProductDto[] | undefined;
  existingDocuments?: IDocumentDto[];
  companyConfiguration: ICompanyConfigurationDto | undefined;
}

export const useAddDocumentModal = ({ contentType, submissionId, products, existingDocuments, companyConfiguration }: IProps) => {
  const { goToDocumentContentDetails } = useAppNavigation();
  const { deleteFile } = useDeleteFile();
  const { addInitialConversionResultForDocument } = useHandleInitialConversionResultForDocument();

  const [isModalOpen, setIsModalOpen] = useState<AddModalOpenModes>(null);
  const { methods: fileUploadFormMethods } = usePrepareFileUploadForm({
    documents: existingDocuments,
    contentType,
    isAddDocumentModalOpen: isModalOpen,
  });

  const [showMissingProducts, setShowMissingProducts] = useState<boolean>(false);

  const { setValue, reset, getValues, handleSubmit, watch } = fileUploadFormMethods;

  var currentDocumentId = watch(FormFields.documentId);

  const { changeDocumentAssociation, isChangingDocumentAssociation } = useChangeDocumentAssociation(
    parseInt(submissionId as string),
  );

  const documentAttributes = getDocumentAttributes(contentType, false);

  const updateAssociations = (documentId: Id, state?: any) => {
    var products = getValues(FormFields.productIds);

    if (!products || products.length === 0) {
      goToDocumentContentDetails(submissionId!, contentType, documentId.toString(), state);
      return;
    }

    changeDocumentAssociation(
      { productIds: products.map((o: { id: any }) => o.id), documentId },
      {
        onSuccess: () => {
          goToDocumentContentDetails(submissionId!, contentType, documentId.toString());
        },
      },
    );
  };

  const handleRemove = () => {
    setValue(FormFields.file, undefined);
    clearFileName();
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const generateProductsCheckboxOptions = (products: IProductDto[]): ICheckboxEntry[] => {
    if (!products) return [];
    return products.map((product) => ({ id: product.productId, value: product.productId, text: product.name }));
  };

  const prepareMatchingProducts = useCallback(
    (prod: IProductDto[]) => {
      const products = prod.filter((o) => o.linkedDocumentIds.some((x) => x === currentDocumentId));
      return generateProductsCheckboxOptions(products || []);
    },
    [currentDocumentId],
  );

  const productsMatchingDocument = useMemo(() => {
    if (!products) return [];

    return prepareMatchingProducts(products);
  }, [prepareMatchingProducts, products]);

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName } =
    useUploadFile(handleRemove);

  //upload methods and properties for secondary documents
  const secondaryDocument = useAddSecondaryDocumentOnModal({ contentType, setValue });

  const { addDocument } = useAddDocument(
    parseInt(submissionId as string),
    contentType,
    fileName,
    getValues(FormFields.file)?.name,
    getValues(FormFields.webLink),
    getValues(FormFields.isCustomerApprovalRequired)!,
    getValues(FormFields.content),
    getValues(FormFields.includeSecondaryDocument)!,
    getValues(FormFields.secondaryfile)?.name,
    secondaryDocument.fileName,
  );

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    const fileRole =
      companyConfiguration?.hasQrdEpilsEnabled === true && contentType === DocumentType.Pil
        ? FileRoles.PrimaryDocFile
        : undefined;
    if (file) {
      const errors = getIsFileValid(contentType, file, fileRole);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(FormFields.file, file);
        uploadFile({ file, documentType: contentType, fileRole });
      }
    }
  };

  const uploadErrors = () => {
    const errors = getCanUpload(
      contentType,
      getValues(FormFields.documentTitle),
      documentAttributes,
      getValues(FormFields.noAudioVideoUrl),
      getValues(FormFields.file),
      getValues(FormFields.webLink),
      getValues(FormFields.hasDigitalVersion),
      getValues(FormFields.isCustomerApprovalRequired)!,
      getValues(FormFields.content),
      getValues(FormFields.includeSecondaryDocument),
      getValues(FormFields.secondaryfile),
      getDocumentProcessingFlowForDocument(contentType, companyConfiguration!),
    );

    if (errors.length > 0) setFileErrors(errors);

    return errors;
  };

  const checkForProduct = (): boolean => {
    const hasMissingProducts = productsMatchingDocument.length > 1 && getValues(FormFields.productIds).length === 0;
    setShowMissingProducts(hasMissingProducts);
    return hasMissingProducts;
  };

  const handleUploadClick = (state?: any) => {
    const errors = uploadErrors();

    if (errors.length === 0 && !checkForProduct()) {
      handleSubmit(() =>
        addDocument(
          {
            documentId: getValues(FormFields.documentId),
            title: getValues(FormFields.documentTitle),
            hasDigitalVersion: getValues(FormFields.hasDigitalVersion),
            includeSecondaryDocument: getValues(FormFields.includeSecondaryDocument),
          },
          {
            onSuccess: (data) => {
              addInitialConversionResultForDocument(
                data.documentId,
                contentType,
                getDocumentProcessingFlowForDocument(contentType, companyConfiguration!),
              );
              updateAssociations(data.documentId, state);
            },
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(null);
    setShowMissingProducts(false);
    clearFileName();
    clearErrors();
    reset({});
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const clearErrors = () => {
    setShowMissingProducts(false);
    setFileErrors([]);
    secondaryDocument.setFileErrors([]);
  };

  const handleOpenModal = (
    productGroupName: string,
    documentId?: number,
    linkUrl = '',
    hasDigitalVersion?: boolean,
    mode: AddModalOpenModes = 'Add',
  ) => {
    let documentTitle = productGroupName;

    if (mode === 'Update') {
      const selectedDocument = existingDocuments?.find((d) => d.documentId === Number(documentId) && d.type === contentType);
      documentTitle = selectedDocument?.documentTitle ?? productGroupName;
    }

    reset((fieldValues: any) => ({
      ...fieldValues,
      [FormFields.documentId]: documentId,
      [FormFields.documentTitle]: documentTitle,
      [FormFields.webLink]: removeHttpPrefixFromLink(linkUrl) ?? '',
      [FormFields.hasDigitalVersion]: hasDigitalVersion ?? undefined,
      [FormFields.content]: '',
    }));
    setIsModalOpen(mode);
    setShowMissingProducts(false);
  };

  const allErrors = fileErrors.concat(secondaryDocument.fileErrors);
  const allIsLoading = isLoading || secondaryDocument.isLoading;

  return {
    handleFileSubmit,
    handleUploadClick,
    handleCancel,
    handleRemove,
    handleOpenModal,
    clearErrors,
    uploadErrors,
    showMissingProducts,
    fileErrors: allErrors,
    isModalOpen,
    isLoading: allIsLoading,
    uploadProgress,
    productsMatchingDocument,
    isChangingDocumentAssociation,
    fileUploadFormMethods,
    secondaryDocument,
  };
};
