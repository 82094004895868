import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from './styles';
import { DocumentType, ISelectOption } from '@common/types';
import { documentTypeToCustomLabelMap } from '@common/constants';
import {
  AuditsForm,
  ContentReportColumns,
  IAuditsForm,
  ContentReportColumnValueToLabelMap,
  ContentReportColumnLabels,
  getContentReportColumns,
} from './useAuditsForm';
import { StyledLabel } from '@common/components/Select/styles';
import { useFormContext } from 'react-hook-form';
import { HookFormSelectAll } from '@common/components/Select/HookFormSelectAll';
import { HookFormCheckbox } from '@common/components/Checkbox/HookFormCheckbox';
import { CheckboxOptionText } from '@common/components/CheckboxOption/styles';
import { ISelectOptions } from '@common/components/Select/SelectAll';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const { Smpc, Pil, Rmm, Dhcp, UserManual, SafetyAlert, ProductInformation, AudioVideo, Audio, Video, LiveChat } = DocumentType;
const documentTypes = [Smpc, Pil, Rmm, Dhcp, UserManual, SafetyAlert, ProductInformation, Audio, Video, AudioVideo, LiveChat];

const { ContentTitle, ContentId, ContentVersion, RetiredDate, RetiredBy, HasEPil } = ContentReportColumns;

export const ContentReportFilters = () => {
  const {
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext<IAuditsForm>();

  const { showEmcLinkAndRevisionDateInAuditReports } = useFeatureFlags();

  const contentTypeOptions = documentTypes.map((t) => ({ label: documentTypeToCustomLabelMap[t] ?? '', value: t }));

  const includeRetired = watch(AuditsForm.includeRetiredContent);

  const [contentTypes, setContentTypes] = useState<ISelectOption[]>([]);

  const selectedContentTypes = watch(AuditsForm.contentTypes);

  const columnOptions = getContentReportColumns(showEmcLinkAndRevisionDateInAuditReports).map((c) => {
    if ((!includeRetired && [RetiredDate, RetiredBy].includes(c)) || [ContentTitle, ContentId, ContentVersion].includes(c)) {
      return { label: ContentReportColumnValueToLabelMap[c] as ContentReportColumnLabels, value: c, disabled: true };
    }

    return { label: ContentReportColumnValueToLabelMap[c] as ContentReportColumnLabels, value: c, disabled: false };
  });

  const handleIncludeRetiredChange = (value: boolean) => {
    if (!value) {
      const prevColumns = structuredClone(getValues(AuditsForm.contentColumns));
      const filteredColumns = prevColumns.filter((c) => ![RetiredDate, RetiredBy].includes(c.value as ContentReportColumns));

      setValue(AuditsForm.contentColumns, filteredColumns);
    }
  };

  const handleContentTypeChange = useCallback(() => {
    const getInsertPosition = (arr: ISelectOptions, value: ContentReportColumns) => {
      var idx = columnOptions.findIndex((x) => x.value === value);

      return arr.findIndex((x) => columnOptions.findIndex((y) => y.value === x.value) > idx);
    };

    const removedColumns = contentTypes.filter((x) => !selectedContentTypes.some((y) => y.value === x.value));
    const addedColumns = selectedContentTypes.filter((x) => !contentTypes.some((y) => y.value === x.value));

    if (addedColumns.some((x) => x.value === Pil)) {
      const filteredColumns = structuredClone(getValues(AuditsForm.contentColumns));

      if (!filteredColumns.some((x) => x.value === HasEPil)) {
        const item = columnOptions.find((x) => x.value === HasEPil)!;
        const idx = getInsertPosition(filteredColumns, item.value);

        if (idx < 0) {
          filteredColumns.push(item);
        } else {
          filteredColumns.splice(idx, 0, item);
        }
        setValue(AuditsForm.contentColumns, filteredColumns);
      }
    } else if (removedColumns?.some((x) => x.value === Pil)) {
      const clonedColumns = structuredClone(getValues(AuditsForm.contentColumns));
      const filteredColumns = clonedColumns.filter((c) => ![HasEPil].includes(c.value as ContentReportColumns));

      setValue(AuditsForm.contentColumns, filteredColumns);
    }
    setContentTypes(selectedContentTypes);
  }, [selectedContentTypes, contentTypes, columnOptions, getValues, setValue]);

  useEffect(() => {
    handleContentTypeChange();
    setContentTypes(selectedContentTypes);
  }, [selectedContentTypes, handleContentTypeChange, contentTypes]);

  return (
    <Grid rowCount={4}>
      <StyledLabel>Select content type(s)</StyledLabel>
      <HookFormSelectAll
        name={AuditsForm.contentTypes}
        options={contentTypeOptions}
        error={errors.contentTypes?.message}
        width="20rem"
        inlineLabel
      />
      <CheckboxOptionText>Include retired content</CheckboxOptionText>
      <HookFormCheckbox name={AuditsForm.includeRetiredContent} onChange={handleIncludeRetiredChange} />
      <StyledLabel>Select columns</StyledLabel>
      <HookFormSelectAll name={AuditsForm.contentColumns} options={columnOptions} width="20rem" inlineLabel />
    </Grid>
  );
};
