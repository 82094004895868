import { DocumentStatus as DocumentStatusType } from '@common/types';
import { Icons } from '@common/icons';
import { UnderlinedLink } from '../UnderlinedLink/styles';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

export interface IDocumentStatus {
  value: DocumentStatusType;
  conversionState: { isFailed: boolean | null; isInProgress: boolean | null };
  onViewClick?: MouseEventHandler<HTMLAnchorElement>;
}

const DocumentStatus = ({ value, conversionState, onViewClick }: IDocumentStatus) => {
  const renderStatus = () => {
    switch (value) {
      case DocumentStatusType.Retired:
        return <Icons.RetiredTag />;
      case DocumentStatusType.Published:
        return <Icons.PublishedTag />;
      case DocumentStatusType.Submitted:
        return <Icons.SubmittedTag />;
      case DocumentStatusType.Discontinued:
        return <Icons.DiscontinuedTag />;
      default:
        if (conversionState?.isInProgress) {
          return <Icons.ConversionInProgrssTag />;
        }
        if (conversionState?.isFailed) {
          return (
            <FailedWrapper>
              <Icons.FailedConversionTag />
              <UnderlinedLink onClick={onViewClick} to="#">
                View
              </UnderlinedLink>
            </FailedWrapper>
          );
        }

        return <Icons.DraftTag />;
    }
  };

  return renderStatus();
};

export default DocumentStatus;

const FailedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  a {
    margin-right: 5px;
    font-size: ${({ theme }) => theme.fontSizes.tiny};
  }
`;
