import styled from 'styled-components';
import { StyledLink } from '../Link/styles';

export const UnderlinedLink = styled(StyledLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.elements.primaryMedium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 600;
  display: flex;
`;
