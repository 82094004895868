import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { WidgetData, Widgets } from '@common/pages/Home/types';
import { newsItems, oldSlaItems, newSlaItems, tutorialItems, bCertifiedImage } from './constants';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { fetchDashboard } from '@common/services/dashboardService';
import { useQuery } from '@tanstack/react-query';
import { ArticleTypes } from '../widgets/ArticleWidget/types';

export const fetchDashboardQueryKey = 'fetchDashboard';

interface DashboardDto {
  date: Date;
  widgets: WidgetData[];
}

interface IDashboardsData {
  draft: DashboardDto | null;
  published: DashboardDto | null;
}

interface DashboardRaw {
  date: Date;
  content: string;
}

interface IFetchDashboardResponse {
  draft: DashboardRaw;
  published: DashboardRaw;
}

const useFetchDashboard = () => {
  const token = useAppSelector(selectToken);
  const { showChristmasDeadlines } = useFeatureFlags();

  const getDefaultWidgetsData = () => {
    var slaItems = showChristmasDeadlines ? newSlaItems : oldSlaItems;
    var widgets = [
      { widgetType: Widgets.NewsWidget, data: { newsItems } } as WidgetData,
      {
        widgetType: Widgets.WelcomeWidget,
        data: {
          title: 'Welcome to your new emc publishing tool',
          subtitle:
            'The emc publishing platform is part of your emc regulatory solution, and is where you can submit your medicines information content for publication on emc.',
        },
      } as WidgetData,
      {
        widgetType: Widgets.ArticleWidget,
        data: {
          title: 'Datapharm becomes a certified B Corporation',
          description:
            'Datapharm is delighted to have joined a growing list of companies which have achieved certified B Corp status. <a target="_blank" href="https://www.datapharm.com/resource-hub/datapharm-becomes-a-certified-b-corp/" class="styles__Link-sc-1fijo5x-0 jsxhCK">Read more &gt;</a>',
          imageSrc: bCertifiedImage,
          type: ArticleTypes.image,
          link: '',
        },
      } as WidgetData,
      { widgetType: Widgets.TutorialsWidget, data: { tutorialItems } } as WidgetData,
      { widgetType: Widgets.SlaWidget, data: { slaItems } } as WidgetData,
    ];

    return { date: new Date(), widgets } as DashboardDto;
  };

  const parseDashboardData = (dashboardData?: DashboardRaw, returnDefault: boolean = false): DashboardDto | null => {
    if (!dashboardData?.content) {
      return returnDefault ? getDefaultWidgetsData() : null;
    }

    return {
      date: dashboardData.date,
      widgets: JSON.parse(dashboardData.content),
    };
  };

  const { data, isFetching } = useQuery<IFetchDashboardResponse, unknown, IDashboardsData>(
    [fetchDashboardQueryKey],
    () => fetchDashboard(token),
    {
      enabled: !!token,
      select: (data) => ({
        published: parseDashboardData(data.published, true),
        draft: parseDashboardData(data!.draft),
      }),
    },
  );

  return {
    publishedDashboard: data?.published,
    draftDashboard: data?.draft,
    isFetching,
  };
};

export default useFetchDashboard;
