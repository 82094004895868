import React, { useState } from 'react';
import {
  ProductGroupWrap,
  LayoutWrapper,
  Card,
  Title,
  Description,
  Subtitle,
  BadgeElementWrapper,
  TableWrapper,
  ContentHeaderWrapper,
  ContentHeaderSummary,
  ContentWrapper,
  MainContentHeaderWrapper,
} from '@common/features/productGroup/styles';
import { IDocumentWithTask, ISubmissionDto } from '@common/features/submission/types';
import { ProductGroupTabs } from '../types';
import ActiveIngredientsTab from '@common/features/productGroup/components/ActiveIngredients/ActiveIngredientsTab';
import MaHolderTab from './tabs/MaHolder/MaHolder';
import ProductTypeTab from './tabs/ProductType/ProductTypeTab';
import { default as ContentTab } from './tabs/Content/Content';
import { DocumentType } from '@common/types';
import { useAppSelector } from '@common/hooks/redux';
import { selectActiveCompany } from '@common/features/user/selectors';
import Tabs from '@common/components/Tabs/Tabs';
import { HeaderBreadcrumb, Link } from '@common/components/SubmissionFlowHeader/styles';
import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';
import Badge from '@common/components/Badge/Badge';
import NoContentPanel from '@common/components/NoContentPanel/NoContentPanel';
import DatapharmTable from '@common/components/DatapharmTable/DatapharmTable';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import SubmissionFlowHeader from '../../../components/SubmissionFlowHeader/SubmissionFlowHeader';
import { IProductTableCallbacks } from '@common/components/DatapharmTable/types';
import { prepareProductsColumns } from '../helpers';
import { IPublicProductGroupState } from '@common/features/productGroup/types';
import { SubmissionStatus } from '@common/services/types';
import { getSubstanceNames } from '@common/components';
import { BackToContentTypeState } from '../../../routing/useAppNavigation';
import { useLocation } from 'react-router';

interface IReadonlyProductGroup {
  dataSource: ISubmissionDto | IPublicProductGroupState;
  continueBtnText?: string;
  productTableCallbacks: IProductTableCallbacks;
  submissionTitle?: string;
  submissionStatus?: SubmissionStatus;
  onCancel: () => void;
  onContinue?: () => void;
  onDocumentDetailsClick: (documentId: string, contentType: DocumentType) => void;
  isSubmissionView?: boolean;
}

const productsTooltipText = 'We define a product as a combination of Brand/generic name, strength(s) and form';

export const ReadonlyProductGroup = ({
  dataSource,
  continueBtnText,
  productTableCallbacks,
  submissionTitle,
  submissionStatus,
  onCancel,
  onContinue,
  onDocumentDetailsClick,
  isSubmissionView,
}: IReadonlyProductGroup) => {
  const location = useLocation();
  const state = location.state as BackToContentTypeState;
  const selectContentType = (!!state && state.contentType !== undefined) ? state.contentType : DocumentType.Smpc;
  const [contentType, setContentType] = useState(selectContentType);
  const { id } = useAppSelector(selectActiveCompany) ?? {};
  const documents = dataSource?.documents as IDocumentWithTask[];
  const activeIngredients = getSubstanceNames(dataSource?.productGroup.substances);

  const productColumns = prepareProductsColumns(false, productTableCallbacks);

  const handleDocumentDetailsClick = (documentId: string) => {
    const document = documents.find((d) => d.documentId.toString() === documentId);
    onDocumentDetailsClick(documentId, document?.type ?? contentType);
  };

  const items = [
    {
      label: ProductGroupTabs.Content,
      content: (
        <ContentTab
          editable={false}
          documents={documents ?? []}
          contentType={contentType}
          onContentTypeSelect={(contentType) => setContentType(contentType)}
          onContentDetailsClick={handleDocumentDetailsClick}
        />
      ),
    },
    {
      label: ProductGroupTabs.ActiveIngredients,
      content: <ActiveIngredientsTab activeIngredientsNames={dataSource?.productGroup.substances.map((a) => a.name) ?? []} />,
    },
    {
      label: ProductGroupTabs.MaHolder,
      content: (
        <MaHolderTab
          editable={false}
          isVisible={dataSource?.productGroup.maHolderCompanyId !== id}
          isSameAsSubmitterCompany={dataSource?.productGroup.maHolderCompanyId === id}
          companyName={dataSource?.productGroup.maHolderCompanyName ?? 'unknown'}
          onSameAsSubmitterCompanyChange={() => {}}
          onCompanyNameChange={() => {}}
        />
      ),
    },
    {
      label: ProductGroupTabs.ProductType,
      content: <ProductTypeTab editable={false} value={dataSource?.productGroup.productType} />,
    },
  ];

  return (
    <>
      <LayoutWrapper>
        <SubmissionFlowHeader
          submissionTitle={submissionTitle!}
          submissionStatus={submissionStatus!}
          title={dataSource?.productGroup.productGroupName}
          activeIngredients={activeIngredients}
          productGroupId={dataSource?.productGroupId ?? null}
          isSubmissionView={isSubmissionView}
        >
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; Product Group
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        <ProductGroupWrap>
          <Card>
            <MainContentHeaderWrapper>
              <ContentHeaderSummary>
                <Title>Product group management</Title>
                <Description>This is where you can add products and content that relate to this product group</Description>
              </ContentHeaderSummary>
            </MainContentHeaderWrapper>
            <Tabs items={items} />
          </Card>
          <Card>
            <ContentWrapper>
              <ContentHeaderWrapper>
                <ContentHeaderSummary>
                  <BadgeElementWrapper>
                    <Subtitle>Products</Subtitle>
                    <IconWithTooltip tooltipId="emc" iconSrc="/icons/info_circle.svg" tooltipText={productsTooltipText} />
                    <Badge value={dataSource?.products.length} data-testid="products-counter" />
                  </BadgeElementWrapper>
                  <Description>Products can only have one SmPC or PIL associated to them at a time.</Description>
                </ContentHeaderSummary>
              </ContentHeaderWrapper>
              <TableWrapper>
                {!dataSource?.products.length || dataSource?.products.length === 0 ? (
                  <NoContentPanel
                    title="No products found"
                    helpMessage="How do I add products?"
                    helpLink={process.env.REACT_APP_HELP_PRODUCT_URL}
                  />
                ) : (
                  <DatapharmTable documents={dataSource?.products ?? []} columns={productColumns} data-testid="products-table" />
                )}
              </TableWrapper>
            </ContentWrapper>
          </Card>
        </ProductGroupWrap>
      </LayoutWrapper>
      <SubmissionFlowFooter onContinue={onContinue} onCancel={onCancel} continueText={continueBtnText} />
    </>
  );
};
