import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lightGreen};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
  overflow-x: auto;
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  row-gap: 20px;
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  row-gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

export const FlowContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  > * {
    flex-basis: 50%;
  }

  @media (max-width: 1450px) {
    flex-flow: column;
    row-gap: 20px;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.elements.primaryMedium};
`;
