import { documentTypeToConversionErrorLabelMap } from '@common/constants';
import { NotifyModal } from '../NotifyModal';
import { UnderlinedLink } from '../UnderlinedLink/styles';
import { DocumentType, ErrorLog } from '@common/types';
import styled from 'styled-components';
import { PleaseWaitLoader } from '../PleaseWaitLoader';
import { ReactSVG } from 'react-svg';
import { SingleError } from '../SingleError';
import { ConversionErrorType, conversionErrorTypeToCustomLabelMap } from '@common/features/document/types';

interface IProps {
  isOpen: boolean;
  contentType: DocumentType;
  documentConversionErrors: ErrorLog[] | undefined;
  showLoader?: boolean;
  onCloseClick: () => void;
}

export const DocumentConversionErrorsModal = ({
  isOpen,
  contentType,
  documentConversionErrors,
  showLoader,
  onCloseClick,
}: IProps) =>
  isOpen ? (
    <NotifyModal
      isOpen={isOpen}
      title={`There was an issue uploading your ${documentTypeToConversionErrorLabelMap[contentType]}`}
      onClose={() => onCloseClick()}
      width="35rem"
      cancelText="Close"
      isLoading={true}
    >
      {showLoader ? (
        <CenteringWrapper>
          <PleaseWaitLoader small />
        </CenteringWrapper>
      ) : (
        documentConversionErrors?.map((e) =>
          e.mainError === ConversionErrorType.UnknownProblem ? (
            <SingleError
              title={conversionErrorTypeToCustomLabelMap[e?.mainError ?? '']!}
              items={!!e ? [e] : []}
              helpLink="mailto:servicedesk@datapharm.com"
              linkText="servicedesk@datapharm.com"
            />
          ) : (
            <SingleError title={conversionErrorTypeToCustomLabelMap[e?.mainError ?? '']!} items={!!e ? [e] : []} />
          ),
        )
      )}
      <UnderlinedLink to={process.env.REACT_APP_HELP_URL!} target="_blank">
        {`How to add an ${contentType}`} <ReactSVG src={'/icons/info_circle.svg'} />
      </UnderlinedLink>
    </NotifyModal>
  ) : null;

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;
