import { Icons } from '@common/icons';
import { SubmissionTaskType } from '@common/types';

interface Props {
  submissionType: SubmissionTaskType;
  isFileValid: boolean;
}

export const SubmissionTaskXmlState = ({ submissionType, isFileValid }: Props) => {
  if (
    submissionType === SubmissionTaskType.ePILNew ||
    submissionType === SubmissionTaskType.ePILUpdate ||
    submissionType === SubmissionTaskType.SmpcNew ||
    submissionType === SubmissionTaskType.SmpcUpdate
  )
    return isFileValid ? <Icons.ValidXml /> : <Icons.InvalidXml />;
};
