import React from 'react';
import { Button, ButtonTypes } from '@common/components';
import { createColumnHelper } from '@tanstack/react-table';
import theme from '@common/theme';
import { ActionType, DocumentType } from '@common/types';
import { DocumentState, ISubmissionDto, ProductChangeType } from '../types';

interface ITableData {
  accessor: string;
  columnName: string | (() => JSX.Element);
  cell?: any;
}

export const createColumns = (tableData: (ITableData | undefined)[]) => {
  const columnHelper = createColumnHelper<any>();

  return tableData.map((x) => {
    return columnHelper.accessor(x!.accessor, {
      header: x!.columnName,
      cell: x!.cell ?? ((info: any) => info.getValue()),
    });
  });
};

export const getActions = (
  actionTypes: ActionType[],
  removeButtonText: string,
  onEditXml: () => void,
  onViewHtml: () => void,
  onProductUpdate?: () => void,
  onProductRemove?: () => void,
  testId: string = '',
) => {
  return (
    <div style={{ display: 'flex' }}>
      {actionTypes?.includes(ActionType.Update) && onProductUpdate && (
        <div style={{ marginRight: '5px' }}>
          <Button
            type={ButtonTypes.TEXT}
            data-testid={`${testId}-Update`}
            text="Update"
            icon="/icons/update.svg"
            height={25}
            onClick={onProductUpdate}
            color={theme.elements.primaryMedium}
          />
        </div>
      )}
      {actionTypes?.includes(ActionType.EditXml) && (
        <div style={{ marginRight: '5px' }}>
          <Button
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/edit_XML.svg"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={onEditXml}
          />
        </div>
      )}
      {actionTypes?.includes(ActionType.ViewHtml) && (
        <div style={{ marginRight: '5px' }}>
          <Button
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/view_html.svg"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={onViewHtml}
          />
        </div>
      )}
      {actionTypes?.includes(ActionType.Remove) && onProductRemove && (
        <div style={{ marginRight: '5px' }}>
          <Button
            type={ButtonTypes.TEXT}
            text={removeButtonText}
            icon="/icons/trash_bin.svg"
            height={25}
            color={theme.elements.removeRed}
            onClick={onProductRemove}
          />
        </div>
      )}
    </div>
  );
};

export const getChangedDocuments = (submission?: ISubmissionDto, documentId?: number) => {
  var changedDocuments = submission?.documents.filter((item) => item.state !== DocumentState.Unchanged);
  return changedDocuments?.length ? changedDocuments : submission?.documents.filter((item) => item.documentId === documentId); // fallback to avoid bug #26344 - should be removed after fixing
};

export const getChangedProducts = (submission?: ISubmissionDto, documentId?: number) => {
  var productsMarkedAsChanged = submission?.products.filter((item) => item.changeType !== ProductChangeType.Unchanged);
  var productsLinkedByDocuments = documentId
    ? submission?.products.filter((item) => item.linkedDocumentIds.includes(documentId))
    : [];

  var changedProducts = Array.from(new Set(productsLinkedByDocuments?.concat(productsMarkedAsChanged ?? [])));

  return changedProducts;
};

export interface ISubmissionTaskAttributes {
  shouldHandleEditXml?: boolean;
  shouldHandleViewHtml?: boolean;
  shouldHandleDocumentUpdate?: boolean;
}

export const getSubmissionTaskAttributes = (type: DocumentType): ISubmissionTaskAttributes => {
  return {
    shouldHandleEditXml: [DocumentType.Smpc, DocumentType.Epil].includes(type),
    shouldHandleViewHtml: [DocumentType.Smpc, DocumentType.Epil].includes(type),
    shouldHandleDocumentUpdate: [
      DocumentType.Smpc,
      DocumentType.Pil,
      DocumentType.Epil,
      DocumentType.Rmm,
      DocumentType.Dhcp,
      DocumentType.UserManual,
      DocumentType.SafetyAlert,
      DocumentType.ProductInformation,
      DocumentType.Audio,
      DocumentType.Video,
    ].includes(type),
  };
};
