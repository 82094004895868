import * as Styled from './styles';
import { Icons } from '@common/icons';

export interface IPleaseWaitLoader {
  small?: boolean;
  backgroundColor?: string;
}

export const PleaseWaitLoader = ({ small = false, backgroundColor }: IPleaseWaitLoader) => (
  <Styled.PleaseWaitLoader small={small} backgroundColor={backgroundColor}>
    <Icons.Spinner />
    Please wait a moment
  </Styled.PleaseWaitLoader>
);
