import { NewsTableWrap, NewsItem, NewsDate, NewsDescription, NewsName } from './styles';
import { Link } from '../../styles';
import { INewsWidget } from './types';
import DashboardSection from '../WidgetBase/DashboardSection';
import { WidgetData } from '../../types';
import { formatDate } from '@common/helpers';
import DOMPurify from 'dompurify';

const NewsWidget = (widgetData: WidgetData) => {
  const { newsItems } = widgetData.data as INewsWidget;

  const sanitizeHtml = (html: string) => {
    return DOMPurify.sanitize(html!, { ALLOWED_ATTR: ['target', 'href'] });
  };

  return (
    <DashboardSection title="What's new">
      <NewsTableWrap>
        {newsItems
          ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .map((x, i) => {
            const addSeparator = i < newsItems.length - 1;
            return (
              <NewsItem addSeparator={addSeparator} key={x.title}>
                <NewsDate>{formatDate(new Date(x.date))}</NewsDate>
                <NewsName>
                  <Link target="_blank" href={x.link}>
                    {x.title}
                  </Link>
                </NewsName>
                <NewsDescription dangerouslySetInnerHTML={{ __html: sanitizeHtml(x.description) }} />
              </NewsItem>
            );
          })}
      </NewsTableWrap>
    </DashboardSection>
  );
};

export default NewsWidget;
