import { useState } from 'react';
import { IValidationModalErrors } from '../../ValidateSubmissionErrors/ValidateSubmissionErrors';
import { mapValidationErrors } from '@common/helpers';
import { useValidateSubmission } from './useValidateSubmission';
import { useAppSelector } from '@common/hooks/redux';
import { selectDocumentConversionResults } from '@common/features/user/selectors';
import { ErrorLog } from '@common/types';
import { IDocumentConversionResult } from '@common/features/user/types';

interface IUseValidateSubmissionDataProps {
  submissionId?: string;
  handleErrors: () => void;
  handleSuccess: () => void;
}

export const useValidateSubmissionData = ({ submissionId, handleErrors, handleSuccess }: IUseValidateSubmissionDataProps) => {
  const [validationErrors, setValidationErrors] = useState({} as IValidationModalErrors);

  const { validateSubmission } = useValidateSubmission();

  const documentConversionResults = useAppSelector(selectDocumentConversionResults);

  const validateSubmissionData = () => {
    setValidationErrors({} as IValidationModalErrors);

    validateSubmission(submissionId!, {
      onSuccess: (data) => {
        console.log(data);
        if (data.length > 0) {
          data = handleConversionsInProgress(data, documentConversionResults);
          setValidationErrors(mapValidationErrors(data));
        }

        if (data.length === 0) {
          handleSuccess();
        } else {
          handleErrors();
        }
      },
    });
  };

  return {
    validationErrors,
    validateSubmissionData,
  };
};

const handleConversionsInProgress = (data: ErrorLog[], documentConversionResults: IDocumentConversionResult[]) => {
  const documentIdsWithOngoingConversion = documentConversionResults
    .filter((dcr) => !dcr.isFinished)
    .map((dcr) => dcr.documentId as number);

  return data.map((d) =>
    documentIdsWithOngoingConversion.includes(d.itemId!) && d.mainError === 'Content Information'
      ? {
          ...d,
          subError: d.subError?.replace('Has failed document conversion', '') + '\r\nConversion in progress',
        }
      : d,
  );
};
