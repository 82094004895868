import { BorderedBox, Image, SectionDescription, VideoWrapper } from './styles';
import DashboardSection from '../WidgetBase/DashboardSection';
import { IArticleWidget } from './types';
import DOMPurify from 'dompurify';
import { WidgetData } from '../../types';

const ArticleWidget = (widgetData: WidgetData) => {
  const { title, description, imageSrc, type: isVideo, link } = widgetData.data as IArticleWidget;
  const html = DOMPurify.sanitize(description!, { ALLOWED_ATTR: ['target', 'href'] });

  return (
    <DashboardSection title="Did you know?">
      <BorderedBox>
        {title && <h3>{title}</h3>}
        {html && <SectionDescription dangerouslySetInnerHTML={{ __html: html }} />}
        {isVideo && link ? (
          <VideoWrapper>
            <iframe title="article" className="wrapper" src={link} allow="fullscreen; picture-in-picture;" />
          </VideoWrapper>
        ) : (
          <Image src={imageSrc!} alt="datapharm-website" />
        )}
      </BorderedBox>
    </DashboardSection>
  );
};

export default ArticleWidget;
