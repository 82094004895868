import React from 'react';
import useAppNavigation, { BackToContentTypeState } from '../../../routing/useAppNavigation';
import { IProductTableCallbacks } from '@common/components/DatapharmTable/types';
import { DocumentType } from '@common/types';
import { ISubmissionDto } from '@common/features/submission/types';
import { useParams } from 'react-router';
import { ReadonlyProductGroup } from './ReadonlyProductGroup';

interface ISubmissionReadonlyProductGroup {
  submission: ISubmissionDto;
}

const SubmissionReadonlyProductGroup = ({ submission }: ISubmissionReadonlyProductGroup) => {
  const { submissionId = '' } = useParams();
  const { goToDashboard, goToProduct, goToDocumentContentDetails } = useAppNavigation();

  const handleDetailsClick = (productId: string) => goToProduct(submissionId, productId);

  const handleDocumentDetailsClick = (documentId: string, contentType: DocumentType) => {
    //There is no route for customer UI to view epil directly, therefore revert to PIL
    //Components Will decided whether to load epil or pil
    const docType = contentType === DocumentType.Epil ? DocumentType.Pil : contentType;
    const backToContentState: BackToContentTypeState = { contentType: docType ?? contentType };
    goToDocumentContentDetails(submissionId, docType, documentId, backToContentState);
  };

  const tableCallbacks: IProductTableCallbacks = {
    onDetailsClick: handleDetailsClick,
  };

  const handleCancel = () => goToDashboard();

  return (
    <ReadonlyProductGroup
      submissionStatus={submission?.status!}
      submissionTitle={submission?.title!}
      dataSource={submission!}
      productTableCallbacks={tableCallbacks}
      onCancel={handleCancel}
      onDocumentDetailsClick={handleDocumentDetailsClick}
      isSubmissionView
    />
  );
};

export default SubmissionReadonlyProductGroup;
