export enum Widgets {
  NewsWidget = 'News widget',
  WelcomeWidget = 'Welcome widget',
  ArticleWidget = 'Article widget',
  TutorialsWidget = 'Tutorials widget',
  SlaWidget = 'Sla widget',
}

export type WidgetData = {
  widgetType: Widgets;
  data: any;
};
