import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import ProductStatusComponent from '@components/ProductStatus/ProductStatus';
import ProductActions from '@components/ProductActions/ProductActions';
import { Button, ButtonTypes, getSubstanceNames } from '@common/components';
import { formatDateTime } from '@common/helpers';
import { IActionCallbacks, IProductTableCallbacks } from '@common/components/DatapharmTable/types';
import { IProductDto, ISubmissionDto, ProductChangeType, ProductStatus } from '@common/features/submission/types';
import { SubmissionTaskType, TaskTypeToDocumentTypeMap, DocumentType } from '@common/types';
import { DocumentState, IDocumentDto, IDocumentWithTask } from '@common/features/submission/types';
import { IDocumentConversionResult } from '@common/features/user/types';
import { ITask } from '../submission/types';

export const prepareProductsColumns = (editable = true, callbacks?: IProductTableCallbacks) => {
  const columnHelper = createColumnHelper<IProductDto>();

  const base = [
    columnHelper.accessor('name', {
      header: 'Product name',
    }),
    columnHelper.accessor('linkedSmpcTitle', {
      header: 'Linked SmPC',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('linkedPilTitle', {
      header: 'Linked PIL',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('lastChangedDate', {
      header: 'Date last updated',
      cell: ({ getValue }) => {
        const value = getValue();

        return value ? formatDateTime(value) : '-';
      },
    }),
    columnHelper.accessor('productId', {
      header: 'Product ID',
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: (info) => <ProductStatusComponent value={info.getValue()} />,
    }),
    columnHelper.display({
      header: 'Details',
      cell: ({ row }) => {
        return (
          <Button
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/eye.svg"
            height={25}
            onClick={() => (callbacks?.onDetailsClick ? callbacks?.onDetailsClick!(row.original.productId.toString()) : () => {})}
          />
        );
      },
    }),
  ];

  return editable
    ? [
        ...base,
        columnHelper.accessor('status', {
          id: 'actions',
          header: 'Actions',
          cell: ({ row, getValue }) => {
            const productId = row.original.productId;
            const productName = row.original.name;
            const linkedSmpc = row.original.linkedSmpcTitle && row.original.linkedSmpcTitle.length > 0;
            const linkedPil = row.original.linkedPilTitle && row.original.linkedPilTitle.length > 0;
            const isProductUnchanged = row.original.changeType === ProductChangeType.Unchanged;
            const isProductDraft = row.original.status === ProductStatus.Draft;

            const shouldHandleRetireClick = !linkedSmpc && !linkedPil && isProductUnchanged && isProductDraft;

            const statusCallbacks: IActionCallbacks = {
              onRemoveClick: () => callbacks?.onRemoveClick!(productId.toString(), productName),
              onUpdateClick: () => callbacks?.onUpdateClick!(productId.toString()),
              onDiscontinueClick: () => callbacks?.onDiscontinueClick!(productId.toString()),
              onReinstatementClick: () => callbacks?.onReinstatementClick!(productId.toString()),
              onRetireClick: shouldHandleRetireClick ? () => callbacks?.onRetirementClick!(productId.toString()) : undefined,
            };

            return <ProductActions status={getValue()} callbacks={statusCallbacks} productId={productId} />;
          },
        }),
      ]
    : base;
};

export const mapProductGroup = (submission?: ISubmissionDto) => {
  const activeIngredients = submission?.productGroup.substances ?? [];
  const substances = getSubstanceNames(activeIngredients);

  return {
    productGroup: submission?.productGroup.productGroupName + substances,
    maHolder: !!submission?.productGroup.maHolderCompanyId,
    companyName: submission?.productGroup.maHolderCompanyName ?? '',
    productType: submission?.productGroup.productType ?? '',
  };
};

const { QRDPilNew, QRDPilUpdate } = SubmissionTaskType;

export const mapDocumentsAndTasks = (
  tasks: ITask[],
  documents: IDocumentDto[],
  documentConversionResults: IDocumentConversionResult[],
) => {
  const allDocumentsWithTasks = documents.map((document) => {
    const tasksForDocument = tasks.filter((task) => task.documentId === document.documentId);
    const matchingTask = tasksForDocument.find((task) => TaskTypeToDocumentTypeMap[task.type] === document.type);

    const retireTask = tasks.find(
      (task) => task.documentId === document.documentId && task.type === SubmissionTaskType.DocumentRetire,
    );

    // if QrdPil then get conversion and isFileValid from QrdEpil
    let taskId = matchingTask?.submissionTaskId;
    if (matchingTask && [QRDPilNew, QRDPilUpdate].includes(matchingTask?.type)) {
      taskId = tasksForDocument.find((t) => t.type === qrdPilToQrdEpilMap[matchingTask?.type])?.submissionTaskId;
    }
    const conversion = documentConversionResults.find((dcr) => dcr.taskId === taskId);
    const isFileProcessable = tasksForDocument.find((t) => t.submissionTaskId === taskId)?.isFileProcessable;

    const isSmpcOrPil = [DocumentType.Smpc, DocumentType.Pil].includes(document.type);

    return {
      ...document,
      taskType: matchingTask?.type ?? retireTask?.type,
      submissionTaskId: matchingTask?.submissionTaskId ?? retireTask?.submissionTaskId,
      sla: matchingTask?.sla ?? null,
      customerLink: matchingTask?.customerLink,

      // Check conversion in progress for SmPC and PIL. Assume that if conversion data is not fetched
      // in the request it means that the it was finished outside of the time window sent in the request
      isConversionInProgress: isSmpcOrPil ? !!conversion && !conversion?.isFinished : null,

      // We can assess the state of conversion only when it is finished. If data not available we assume that it is finished
      isConversionFailed:
        isSmpcOrPil && !!matchingTask
          ? (conversion?.isFinished && !conversion?.isFileValid) || (!conversion && !isFileProcessable)
          : null,
    } as IDocumentWithTask;
  });

  const documentsWithTasksForSubmissionSummary = allDocumentsWithTasks.filter(
    (document) => ![DocumentState.Unchanged, DocumentState.PendingDigitalVersion].includes(document.state),
  );

  return { allDocumentsWithTasks, documentsWithTasksForSubmissionSummary };
};

const qrdPilToQrdEpilMap: qrdPilToQrdEpil = {
  [SubmissionTaskType.QRDPilNew]: SubmissionTaskType.QRDEpilNew,
  [SubmissionTaskType.QRDPilUpdate]: SubmissionTaskType.QRDEpilUpdate,
};

type qrdPilToQrdEpil = {
  [key in SubmissionTaskType]?: SubmissionTaskType;
};
