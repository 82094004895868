import { sendAxiosFileRequest } from '@common/api/uploadFileWithProgression';
import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { ProductGroupSubstancesUpdateCommand, RequestType } from '@common/services/types';
import { DocumentType, Id } from '@common/types';
import { AxiosProgressEvent } from 'axios';

const basePath = process.env.REACT_APP_API_PATH;

const fetchSubmissionTaskNotes = async (token: string, taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/notes`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchProducts = async (token: string, queryString: string) => {
  const url = buildUrl(`/product`, basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchDocumentVersions = async (token: string, documentId: string, documentType: DocumentType) => {
  const url = buildUrl(`/document/${documentId}/document-type/${documentType}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchCompanies = async (token: string, queryString: string) => {
  const url = buildUrl(`/company`, basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchCompanyConfiguration = async (token: string, companyid: number) => {
  const url = buildUrl(`/configuration/company/${companyid}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchXml = async (token: string, taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/xml`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchHtml = async (token: string, taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/html`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const validateXml = async (token: string, taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/html-preview`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const regenerateDocument = async (token: string, taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/document-regeneration`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const putXml = async (token: string, taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/xml`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const postDraftForPublicationXmlUpdate = async (token: string, productGroupId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/live-xml-update-draft`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: { productGroupId, documentId } });
};

const postLiveXmlUpdate = async (token: string, submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/xml`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const changeDocumentAssociation = async (token: string, submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/product-assignments`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const downloadImage = async (token: string, taskId: string, name: string) => {
  const url = buildUrl(`/submission-task/${taskId}/image/${name}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const uploadImage = async (
  token: string,
  taskId: string,
  data: FormData,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) => {
  const url = buildUrl(`/submission-task/${taskId}/image`, basePath);

  return sendAxiosFileRequest(token, RequestType.PUT, data, url, onUploadProgress);
};

const deleteImage = async (token: string, taskId: string, name: string) => {
  const url = buildUrl(`/submission-task/${taskId}/image/${name}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.DELETE });
};

const fetchMatchingProductsForCompany = async (token: string, companyId: Id, substances?: string[]) => {
  let query = `companyId=${companyId}`;

  if (substances && substances.length > 0) {
    query += substances.map((s) => `&substances=${s}`).join('');
  }

  const url = buildUrl(`/product-group/matching-products`, basePath, query);

  return handleRequest({ token, url, requestType: RequestType.GET });
};
const updateProductGroupSubstances = async (token: string, productGroupId: Id, command: ProductGroupSubstancesUpdateCommand) => {
  const url = buildUrl(`/product-group/${productGroupId}/substances`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PATCH, data: command });
};

const fetchCompanyTransfers = async (token: string) => {
  const url = buildUrl(`/company/transfer-list`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchLiveChats = async (token: string) => {
  const url = buildUrl(`/company/live-chat`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const deleteLiveChat = async (token: string, id: string) => {
  const url = buildUrl(`/company/live-chat/${id}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.DELETE });
};

const updateLiveChat = async (token: string, data: unknown) => {
  const url = buildUrl(`/company/live-chat`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data });
};

const fetchHolidays = async (token: string) => {
  const url = buildUrl(`/holiday`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const deleteHoliday = async (token: string, date: string) => {
  const url = buildUrl(`/holiday/${date}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.DELETE });
};

const addHoliday = async (token: string, date: string) => {
  const url = buildUrl(`/holiday/${date}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST });
};

const scheduleCompanyTransfer = async (
  token: string,
  data: FormData,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) => {
  const url = buildUrl('/company/products-transfer', basePath);

  return sendAxiosFileRequest(token, RequestType.POST, data, url, onUploadProgress);
};

const fetchProductFamilies = async (token: string, search?: string) => {
  const url = buildUrl(`/product-family`, basePath, search && `nameStartsWith=${search}`);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchProductFamily = async (token: string, id: Id) => {
  const url = buildUrl(`/product-family/${id}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const updateProductFamilyName = async (token: string, productFamilyId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-family/${productFamilyId}/name`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const updateProductFamilyType = async (token: string, productFamilyId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-family/${productFamilyId}/type`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const migrateProductGroup = async (
  token: string,
  sourceProductFamilyId: Id,
  destinationProductFamilyId: Id,
  dataSet: unknown,
) => {
  const url = buildUrl(
    `/product-family/${sourceProductFamilyId}/migrate-product-group-to/${destinationProductFamilyId}`,
    basePath,
  );

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const fetchMatchingProductGroupsForFamily = async (token: string, productFamilyId: Id, companyId: Id) => {
  const url = buildUrl(`/product-family/${productFamilyId}/matching-product-groups`, basePath, `companyId=${companyId}`);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchMatchingProductGroups = async (token: string, companyId: Id) => {
  const url = buildUrl(`/product-group`, basePath, `companyId=${companyId}`);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const migrateProductGroupToBrandNewProductFamily = async (token: string, dataSet: unknown) => {
  const url = buildUrl(`/product-family/migrate-product-group-to-new-product-family`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const publishDashboard = async (token: string, content: string) => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: { content } });
};

const saveDashboardDraft = async (token: string, content: string) => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: { content } });
};

const deleteDashboardDraft = async (token: string) => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ token, url, requestType: RequestType.DELETE });
};

export {
  fetchProducts,
  fetchCompanies,
  fetchCompanyConfiguration,
  fetchSubmissionTaskNotes,
  fetchXml,
  fetchHtml,
  validateXml,
  regenerateDocument,
  putXml,
  changeDocumentAssociation,
  uploadImage,
  downloadImage,
  deleteImage,
  updateProductGroupSubstances,
  postDraftForPublicationXmlUpdate,
  postLiveXmlUpdate,
  fetchCompanyTransfers,
  scheduleCompanyTransfer,
  fetchLiveChats,
  updateLiveChat,
  deleteLiveChat,
  fetchHolidays,
  deleteHoliday,
  addHoliday,
  fetchDocumentVersions,
  fetchMatchingProductsForCompany,
  fetchProductFamilies,
  updateProductFamilyName,
  updateProductFamilyType,
  migrateProductGroup,
  fetchMatchingProductGroupsForFamily,
  fetchProductFamily,
  fetchMatchingProductGroups,
  migrateProductGroupToBrandNewProductFamily,
  publishDashboard,
  saveDashboardDraft,
  deleteDashboardDraft,
};
