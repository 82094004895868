import styled from 'styled-components';

export const Link = styled.a`
  color: ${({ theme }) => theme.elements.primaryMedium};
`;

export const WidgetEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`;
