import { WidgetData } from '../../types';
import DashboardSection from '../WidgetBase/DashboardSection';
import { IWelcomeWidget } from './types';

const WelcomeWidget = (widgetData: WidgetData) => {
  const { title, subtitle } = widgetData.data as IWelcomeWidget;
  return (
    <DashboardSection>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </DashboardSection>
  );
};

export default WelcomeWidget;
