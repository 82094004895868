import React from 'react';

import { prepareTaskDocumentColumns } from './helpers';
import { TaskTypeToStringMap, getTaskIcon } from '@common/helpers';

import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { SubmissionTaskXmlState } from './SubmissionTaskXmlState';

import { useFetchCompanyConfiguration } from '@common/features/configuration/useFetchCompanyConfiguration';

import { getDocumentAttributes } from '@common/features/document/helpers';
import { DocumentType, TaskTypeToDocumentTypeMap } from '@common/types';
import { IDocumentDto, ISubmissionTaskDto } from '@common/features/submission/types';
import { ITaskDocumentTableCallbacks } from '@common/components/DatapharmTable/types';

import { SubmissionTaskDetailsUnitWrapper } from '../../styles';
import { StyledSVG, TableOverflowContainer } from '@common/styles';
import * as Styled from './styles';

interface Props {
  submissionTask: ISubmissionTaskDto;
  documents: IDocumentDto[];
  isLoading: boolean;
  actionCallbacks: (documentType?: DocumentType) => ITaskDocumentTableCallbacks;
  onDetailsClick: (documentId: string) => void;
  onContentTitleClick: (documentId: string) => void;
  onContentDescriptionClick: (text: string) => void;
  onViewContent: (content: string, title: string) => void;
  isReadonly?: boolean;
  isAdmin: boolean;
}

export const TaskDocumentTable = ({
  submissionTask,
  documents,
  isLoading,
  actionCallbacks,
  onDetailsClick,
  onContentTitleClick,
  onContentDescriptionClick,
  onViewContent,
  isReadonly = false,
  isAdmin = false,
}: Props) => {
  const { companyConfiguration } = useFetchCompanyConfiguration(submissionTask.companyId);
  const contentType = TaskTypeToDocumentTypeMap[submissionTask.type];

  const documentAttributes = getDocumentAttributes(contentType!, isAdmin);

  const columns = prepareTaskDocumentColumns(
    actionCallbacks,
    onContentTitleClick,
    onDetailsClick,
    onContentDescriptionClick!,
    onViewContent,
    documentAttributes,
    isReadonly,
    companyConfiguration,
  );

  return (
    <SubmissionTaskDetailsUnitWrapper>
      <Styled.HeaderWrapper>
        <Styled.HeaderTextWrapper>{TaskTypeToStringMap[submissionTask.type]}</Styled.HeaderTextWrapper>
        <StyledSVG height={25} src={getTaskIcon(submissionTask.type)} />
        <SubmissionTaskXmlState submissionType={submissionTask.type} isFileValid={submissionTask.isFileValid} />
      </Styled.HeaderWrapper>
      {!isLoading && (
        <Styled.TableWrapper>
          <TableOverflowContainer>
            <DatapharmTable columns={columns} documents={documents} data-testid="task-document-table" />
          </TableOverflowContainer>
        </Styled.TableWrapper>
      )}
    </SubmissionTaskDetailsUnitWrapper>
  );
};
