import styled from 'styled-components';

export const Image = styled.img`
  position: relative;
  width: 100%;
`;

export const SectionDescription = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
`;

export const BorderedBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 15px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGreen};
  border-radius: 10px;
  padding: 10px;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;