import { RegisterOptions, useFormContext } from 'react-hook-form';
import { DatePickerWrapper } from './DatePickerWrapper';

interface IHookFormDatePicker {
  placeholder?: string;
  label?: string;
  name: string;
  maxDate?: Date | null;
  disabled?: boolean;
  rules?: RegisterOptions<any, string>;
}

export const HookFormDatePicker = (props: IHookFormDatePicker) => {
  const { setValue, watch, register } = useFormContext();
  const { name, rules } = props;

  if (rules) {
    register(name, {
      ...rules,
    });
  }

  const handleChange = (date: Date | null) => {
    setValue(name, date, { shouldValidate: true, shouldDirty: true });
  };

  const value = watch(name);

  return <DatePickerWrapper {...props} onChange={handleChange} value={value} />;
};
