import { documentTypeToCustomLabelMap } from '@common/constants';
import { DocumentType } from '@common/types';
import { DocumentProcessingFlow } from '../types';

export interface IFileTitleAndSummary {
  primaryFileDescription: string;
  secondaryFileDescription: string;
  primaryFileHeader: string;
  secondaryFileHeader: string;
}

export const useGeneratePrimaryFileDescription = (contentType: DocumentType, documentProcessingFlow: DocumentProcessingFlow): IFileTitleAndSummary => {
  const documentTypeDescription = documentTypeToCustomLabelMap[contentType];

  const primaryFileDescription = `${
    contentType === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.qrdPil ? 'QRD ' : ''
  }${documentTypeDescription}`;

  const secondaryFileDescription = `${
    contentType === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.standard ? 'PDF ' : ''
  }${documentTypeDescription}`;

  const primaryFileHeader =
    contentType === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.qrdPil
      ? 'This is the word QRD document which we will use to convert to an ePil, e-Pils are specifcally formatted PILs, that are accessible to the visual impaired and can be interpreted by screen readers'
      : '';

  const secondaryFileHeader =
    contentType === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.qrdPil
      ? 'PDF copy of your PIL that users can print and read it easily, please note that if you did not provide a seperate pdf version we have created one using the word document above.'
      : '';

  return {
    primaryFileDescription,
    secondaryFileDescription,
    primaryFileHeader,
    secondaryFileHeader,
  };
};
