import React from 'react';
import { DashboardWrapper, Content, LeftPanel, RightPanel, Row, FlowContainer } from './styles';
import NewsWidget from '@common/pages/Home/widgets/NewsWidget/NewsWidget';
import WelcomeWidget from '@common/pages/Home/widgets/WelcomeWidget/WelcomeWidget';
import ArticleWidget from '@common/pages/Home/widgets/ArticleWidget/ArticleWidget';
import TutorialsWidget from '@common/pages/Home/widgets/TutorialsWidget/TutorialsWidget';
import SlaWidget from '@common/pages/Home/widgets/SlaWidget/SlaWidget';
import { Widgets } from '@common/pages/Home/types';
import useFetchDashboard from '@common/pages/Home/hooks/useFetchDashboard';
import { PleaseWaitLoader } from '@common/components/PleaseWaitLoader';

const Dashboard = () => {
  const { publishedDashboard, isFetching } = useFetchDashboard();

  function getDataForWidget(widgetType: string) {
    return publishedDashboard?.widgets?.find((widget: any) => widget.widgetType === widgetType)!;
  }

  return (
    <DashboardWrapper>
      {isFetching ? (
        <PleaseWaitLoader backgroundColor="white" />
      ) : (
        <>
          <Content>
            <WelcomeWidget {...getDataForWidget(Widgets.WelcomeWidget)} />
            <Row>
              <LeftPanel>
                <FlowContainer>
                  <NewsWidget {...getDataForWidget(Widgets.NewsWidget)} />
                  <ArticleWidget {...getDataForWidget(Widgets.ArticleWidget)} />
                </FlowContainer>
              </LeftPanel>
              <RightPanel>
                <TutorialsWidget {...getDataForWidget(Widgets.TutorialsWidget)} />
                <SlaWidget {...getDataForWidget(Widgets.SlaWidget)} />
              </RightPanel>
            </Row>
          </Content>
        </>
      )}
    </DashboardWrapper>
  );
};
export default Dashboard;
