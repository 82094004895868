import { DocumentType } from '@common/types';
import * as Styled from './styles';
import { useState } from 'react';

export const Html = ({ html, docType }: { html: string; docType: DocumentType | undefined }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const createMarkup = () => ({
    __html: getContent(html, docType),
  });

  function getContent(html: string | undefined, docType: DocumentType | undefined) {
    if (!html) return '';
    return [DocumentType.Smpc].some((x) => x === docType)
      ? FixNesting(WrapTables(html))
      : html;
  }

  function WrapTables(html: string) {
    var tableExpression = new RegExp('<table.*?</table>', 'ig');
    return html.replace(tableExpression, '<div class="emc-table-responsive">$&</div>');
  }

  function FixNesting(html: string) {
    let htmlTemplate = getHtmlTemplate(html);
    let allDetails = Array.from(htmlTemplate.content.querySelectorAll('details'));

    try {
      allDetails.reduce(
        (acc, detail) => {
          if (!detail.classList.contains('paragraph')) {
            return { ...acc, subParagraphs: [...acc.subParagraphs, detail] };
          }

          acc.subParagraphs.forEach((subParagraph) => {
            acc.paragraph.append(subParagraph);
          });

          return { paragraph: detail, subParagraphs: [] as HTMLDetailsElement[] };
        },
        { paragraph: allDetails[0], subParagraphs: [] as HTMLDetailsElement[] },
      );
    } catch (e) {
      console.log(e);
    }

    return htmlTemplate.innerHTML;
  }

  function getHtmlTemplate(html: string) {
    html = html.trim();
    const template = document.createElement('template');
    template.innerHTML = html;
    return template;
  }

  function ExpandAllDetails() {
    var newExpandedValue = !detailsExpanded;
    let container = document.getElementById('html-content');
    let details = container ? Array.from(container.querySelectorAll('details')) : [];
    details.forEach((detail) => {
      detail.open = newExpandedValue;
    });
    setDetailsExpanded(newExpandedValue);
  }

  return (
    <Styled.Html>
      <Styled.ExpandAllButton onClick={ExpandAllDetails}>
        <p>
          <b>{detailsExpanded ? 'Collapse All' : 'Expand All'}</b>
        </p>
        <Styled.ExpandSVG
          className={detailsExpanded ? 'expand-icon open' : 'expand-icon closed'}
          src="/icons/expand_more_circle.svg"
        />
      </Styled.ExpandAllButton>
      <div id="html-content" dangerouslySetInnerHTML={createMarkup()} />
    </Styled.Html>
  );
};
