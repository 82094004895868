import {
  ChangeType,
  DocumentState,
  DocumentType,
  IAssignmentChangesDto,
  IDocumentDto,
} from '@common/features/submission/types';
import { TextForChanges } from '@common/styles';

interface Props {
  documentsData: IDocumentDto[];
  assignments: IAssignmentChangesDto[];
  showChanges: boolean;
}

export const AssociatedContentCell = (props: Props) => {
  const shouldRenderOldAudioVideo = !!props.documentsData.find(x => x.type === DocumentType.AudioVideo);
  const docTypes = getDocTypes(shouldRenderOldAudioVideo);
  return (
    <>
      <div>
        {docTypes.map((dt) =>
          returnContentCount(props.documentsData, props.assignments, props.showChanges, dt.documentType, dt.description),
        )}
      </div>
    </>
  );
};

interface DocTypeAndDescription {
  documentType: DocumentType;
  description: string;
}

const getDocTypes = (shouldHandleAudioVideoType: boolean) => {
  const docTypes: DocTypeAndDescription[] = [
    { documentType: DocumentType.Rmm, description: 'RMMs' },
    { documentType: DocumentType.Dhcp, description: 'DHPC' },
    { documentType: DocumentType.UserManual, description: 'User Manuals' },
    { documentType: DocumentType.SafetyAlert, description: 'Safety alerts' },
    { documentType: DocumentType.ProductInformation, description: 'Product Information' },
    { documentType: DocumentType.Audio, description: 'Audio' },
    { documentType: DocumentType.Video, description: 'Video' },
    { documentType: DocumentType.LiveChat, description: 'Live Chat' },
  ];

  if (shouldHandleAudioVideoType) {
    docTypes.push({ documentType: DocumentType.AudioVideo, description: 'AudioVideo' })
  }

  return docTypes
}

const returnContentCount = (
  documentsData: IDocumentDto[],
  assignmentsData: IAssignmentChangesDto[],
  showChanges: boolean,
  documentType: DocumentType,
  documentTypeName: string,
) => {
  var documentsOnProduct = documentsData.filter((item) => item.type === documentType) ?? [];

  var documentCount = documentsOnProduct.length;

  var assignedButDocumentNotPublished = assignmentsData.filter(
    (ad) =>
      ad.changeType === ChangeType.Added &&
      ad.documentType === documentType &&
      documentsOnProduct.filter((dop) => dop.documentId === ad.documentId).length === 0,
  );

  var modified = assignmentsData.filter((as) => as.documentType === documentType);

  var hasChanged =
    documentsData.filter((item) => item.type === documentType && item.state !== DocumentState.Unchanged).length > 0 ||
    modified.length > 0;

  assignedButDocumentNotPublished.forEach((m) => {
    documentCount++;
  });

  const text = `${documentTypeName} - ${documentCount}`;
  if (!hasChanged || !showChanges) return <p key={documentTypeName}>{text}</p>;
  else return <TextForChanges key={documentTypeName}>{text}</TextForChanges>;
};
