import { debounce } from '@common/utils/utils';
import React, { useEffect, useLayoutEffect } from 'react';

type IsOverFlowCallback = (hasOverflow: boolean) => void;

export const useIsOverflow = (ref: React.RefObject<HTMLSpanElement>, callback?: IsOverFlowCallback, deps?: Array<any>) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  const calculateOverflow = () => {
    const { current } = ref;

    if (!current) {
      return;
    }

    const hasOverflow = current.scrollWidth > current.clientWidth;

    setIsOverflow(hasOverflow);

    if (callback) {
      callback(hasOverflow);
    }
  };

  useLayoutEffect(() => {
    const debouncedcalculateOverflow = debounce(calculateOverflow, 500);
    window.addEventListener('resize', debouncedcalculateOverflow);

    return () => {
      window.removeEventListener('resize', debouncedcalculateOverflow);
    };
  }, [callback, ref]);

  useEffect(() => {
    calculateOverflow();
  }, deps)

  return isOverflow;
};
