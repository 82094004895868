export type IArticleWidget = {
  title?: string | null;
  description?: string | null;
  imageSrc?: string | null;
  link?: string | null;
  type: ArticleTypes;
};

export enum ArticleWidgetFields {
  title = 'title',
  description = 'description',
  type = 'type',
  link = 'link',
  imageSrc = 'imageSrc',
}

export enum ArticleTypes {
  video = 'Video',
  image = 'Image',
}

export interface IArticleWidgetForm {
  [ArticleWidgetFields.title]: string | null;
  [ArticleWidgetFields.description]: string | null;
  [ArticleWidgetFields.imageSrc]: string | null;
  [ArticleWidgetFields.link]: string | null;
  [ArticleWidgetFields.type]: ArticleTypes;
}
