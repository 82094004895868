import { getDocumentTypeLabel } from '@common/helpers';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { TaskTypes, DocumentType } from '@common/types';

export const useSubmissionTaskTypes = () => {
  const {
    showLiveChat,
  } = useFeatureFlags();

  return [
    { id: 1, text: getDocumentTypeLabel(DocumentType.Smpc), value: TaskTypes.Smpc },
    { id: 2, text: getDocumentTypeLabel(DocumentType.Pil), value: TaskTypes.Pil },
    { id: 10, text: getDocumentTypeLabel(DocumentType.Epil), value: TaskTypes.Epil },
    { id: 3, text: getDocumentTypeLabel(DocumentType.Rmm), value: TaskTypes.Rmm },
    { id: 4, text: getDocumentTypeLabel(DocumentType.Dhcp), value: TaskTypes.Dhcp },
    { id: 8, text: getDocumentTypeLabel(DocumentType.UserManual), value: TaskTypes.UserManual },
    { id: 5, text: getDocumentTypeLabel(DocumentType.SafetyAlert), value: TaskTypes.SafetyAlert },
    { id: 6, text: getDocumentTypeLabel(DocumentType.ProductInformation), value: TaskTypes.ProductInfo },
    { id: 7, text: getDocumentTypeLabel(DocumentType.Audio), value: TaskTypes.Audio },
    { id: 11, text: getDocumentTypeLabel(DocumentType.Video),value: TaskTypes.Video },
    showLiveChat && { id: 9, text: getDocumentTypeLabel(DocumentType.LiveChat), value: TaskTypes.LiveChat },
  ].filter((x) => x);
};
