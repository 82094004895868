import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';
import { Id } from '@common/types';
import { DocumentType } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH;

const fetchDocumentVersionHistory = async (token: string, documentType: DocumentType, documentId: number) => {
  const url = buildUrl(`/document/${documentType}/${documentId}/history`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const requestEpilStandard = async (token: string, submissionId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/request-epil`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: {} });
};

const requestEpil = async (token: string, submissionId: Id, documentId: Id, data: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/request-word-epil`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data });
};

const fetchDocumentConversionResults = async (token: string, fromUtcDate?: string, toUtcDate?: string) => {
  const url = buildUrl(`/document-conversion/results`, basePath, getQueryString(fromUtcDate, toUtcDate));

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchDocumentConversionErrors = async (token: string, taskid: number) => {
  const url = buildUrl(`/document-conversion/submission-task/${taskid}/errors`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const getQueryString = (fromUtcDate?: string, toUtcDate?: string) => {
  var queryParts = [];

  if (fromUtcDate) {
    queryParts.push(`fromUtcDate=${fromUtcDate}`);
  }

  if (toUtcDate) {
    queryParts.push(`toUtcDate=${toUtcDate}`);
  }

  return queryParts.join('&');
};

export {
  fetchDocumentVersionHistory,
  requestEpilStandard,
  requestEpil,
  fetchDocumentConversionResults,
  fetchDocumentConversionErrors,
};
