import React from 'react';
import { useMsal } from '@azure/msal-react';
import {
  InsufficientPermissionsText,
  InsufficientPermissionsTextHeader,
  InsufficientPermissionsTextRegular,
  InsufficientPermissionsTextButtons,
  InsufficientPermissionsTop,
  InsufficientPermissionsWrap,
  InsufficientPermissionsLogo,
} from './styles';
import Footer from '../Footer/Footer';
import { ButtonTypes } from '@common/components/Button/types';
import { Button } from '@common/components';
import { serviceDeskEmail } from '@common/constants';

export const InsufficientPermissionsInfo = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      idTokenHint: instance.getActiveAccount()?.idToken,
    });
  };

  return (
    <InsufficientPermissionsWrap>
      <InsufficientPermissionsTop>
        <InsufficientPermissionsLogo src="/publisherLogo.png" />
        <InsufficientPermissionsText>
          <InsufficientPermissionsTextHeader>Sorry to say, but...</InsufficientPermissionsTextHeader>
          <InsufficientPermissionsTextRegular>
            <p>
              <strong>You have insufficient permissions to access emc publisher.</strong>
            </p>
            <p>
              Please contact the service desk at <strong>{serviceDeskEmail}</strong>
              <br />
              or call us on 01372 371 44
            </p>
          </InsufficientPermissionsTextRegular>
        </InsufficientPermissionsText>
        <InsufficientPermissionsTextButtons>
          <Button text="Log In" type={ButtonTypes.SUCCESS} onClick={handleLogout} />
        </InsufficientPermissionsTextButtons>
      </InsufficientPermissionsTop>
      <Footer hideLinks={false} />
    </InsufficientPermissionsWrap>
  );
};
