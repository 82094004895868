import {
  IProductTypeSelectOption,
  IsTruthySelectItemLabel,
  IsTruthySelectItemValue,
  ProductTypeSelectLabels,
  ProductTypeSelectValues,
  DocumentType,
  DocumentStatus,
} from './types';

export const dateTimeFormat = 'dd-LLL-yyyy HH:mm:ss';
export const dateTimeFormatNoSeconds = 'dd-LLL-yyyy HH:mm';
export const dateFormat = 'dd-LLL-yyyy';

export const isTruthySelectOptions = [
  { label: IsTruthySelectItemLabel.Yes, value: IsTruthySelectItemValue.Yes },
  { label: IsTruthySelectItemLabel.No, value: IsTruthySelectItemValue.No },
];

export const metadataSelectOptions = [
  { label: IsTruthySelectItemLabel.Yes, value: IsTruthySelectItemValue.Yes },
  { label: IsTruthySelectItemLabel.No, value: IsTruthySelectItemValue.No },
  { label: IsTruthySelectItemLabel.NotSpecified, value: IsTruthySelectItemValue.Blank },
];

export const glutenSelectOptions = [
  { label: IsTruthySelectItemLabel.YesGluten, value: IsTruthySelectItemValue.Yes },
  { label: IsTruthySelectItemLabel.NoGluten, value: IsTruthySelectItemValue.No },
  { label: IsTruthySelectItemLabel.NotSpecified, value: IsTruthySelectItemValue.Blank },
];

export const lactoseSelectOptions = [
  { label: IsTruthySelectItemLabel.YesLactose, value: IsTruthySelectItemValue.Yes },
  { label: IsTruthySelectItemLabel.NoLactose, value: IsTruthySelectItemValue.No },
  { label: IsTruthySelectItemLabel.NotSpecified, value: IsTruthySelectItemValue.Blank },
];

export const productTypeSelectOptions: IProductTypeSelectOption[] = [
  {
    label: ProductTypeSelectLabels.BRANDED_INNOVATOR,
    value: ProductTypeSelectValues.BRANDED_INNOVATOR,
  },
  {
    label: ProductTypeSelectLabels.GENERIC,
    value: ProductTypeSelectValues.GENERIC,
  },
  {
    label: ProductTypeSelectLabels.BRANDED_GENERIC,
    value: ProductTypeSelectValues.BRANDED_GENERIC,
  },
];

export const linkPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
export const atcCodePattern = `^[A-Za-z][0-9]{2}(?:[A-Za-z]{1,2}|[A-Za-z]{2}[0-9]{2})?$`;
export const searchInputPattern = new RegExp('^[^ ]{3}');
export const dateInputPattern = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}');

export const UNITS_DIVIDER = 1024;

export const FILE_SIZE_LIMIT = 5 * UNITS_DIVIDER * UNITS_DIVIDER;

export const documentTypeToCustomLabelMap: DocumentTypeToCustomLabel = {
  [DocumentType.Smpc]: 'SmPC',
  [DocumentType.Pil]: 'PIL',
  [DocumentType.Rmm]: 'RMM',
  [DocumentType.UserManual]: 'User Manual',
  [DocumentType.SafetyAlert]: 'Safety alert',
  [DocumentType.AudioVideo]: 'Audio & video',
  [DocumentType.LiveChat]: 'Live Chat',
  [DocumentType.ProductInformation]: 'Product Information',
  [DocumentType.Dhcp]: 'DHPC',
  [DocumentType.Epil]: 'ePIL',
  [DocumentType.Video]: 'Video',
  [DocumentType.Audio]: 'Audio',
};

export const documentTypeToConversionErrorLabelMap: DocumentTypeToCustomLabel = {
  [DocumentType.Smpc]: 'SmPC',
  [DocumentType.Pil]: 'ePIL',
};

type DocumentTypeToCustomLabel = {
  [key in DocumentType]?: string;
};

export const associatedContentStatuses = [DocumentStatus.Draft, DocumentStatus.Published, DocumentStatus.Submitted];

export const emcTooltipText = 'This is the product name as it will appear on the emc';
export const medicinalTooltipText = 'This is the Medicinal Product Name as shown in section 1 of the SmPC';

export const maxSubmissionReasonLength = 500;

export const generalFormErrors = {
  htmlContentLinkValidation: 'Please check that your links include https:// at the beginning',
  noHttpOrHttpsInLink: 'Please check that your link does not include https:// or http://',
  resubmitNoteLength: 'Max. length: 500 characters',
  noteLength: 'Max. length: 1000 characters',
  noteRequired: 'Note is required',
  liveChatTabLength: 'Tab title cannot exceed 50 characters',
  extraInformationLength: 'Max. length: 200 characters',
  additionalInformationMaxLength: 'Max. length: 1000 characters',
  submissionSummaryConfirmation: 'Please indicate that you are happy to submit',
  numericValidation: 'Please enter numeric value',
  noActiveIngredients: 'Please provide an active ingredient(s) or select the no active ingredient checkbox',
  activeIngredientAlreadyAdded: 'Active ingredient already added',
  cancelationReasonLength: 'Max. length: 500 characters',
  contactGroupMaxLength: 'Max. length: 200 characters',
  requestChangesLength: 'Max. length: 1000 characters',
  documentTitleLength: 'Max. length: 200 characters',
  submissionReasonLength: `Max length ${maxSubmissionReasonLength} chars`,
  productGroupAlreadyExist: 'Product group already exists',
};

export const FilterStateId = {
  Submissions: 'Submissions',
  SubmissionTasks: 'SubmissionTasks',
  Products: 'Products',
};

export const serviceDeskEmail = 'servicedesk@datapharm.com';
