import { abortUpload } from '@api/uploadFileWithProgression';
import { DocumentProcessingFlow } from '@common/features/document/components/DocumentContentDetails/types';
import { getCanUpload, getIsFileValid, IDocumentAttributes } from '@common/features/document/helpers';
import { useAddSecondaryDocumentOnModal } from '@common/features/document/hooks/useAddSecondaryFile';
import { AddModalOpenModes, FileRoles, FormFields } from '@common/features/document/types';
import { IDocumentDto } from '@common/features/submission/types';
import { removeHttpPrefixFromLink } from '@common/helpers';
import { DocumentType } from '@common/types';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { useUploadFile } from '@hooks/useUploadFile';
import { useReplaceDocument } from 'apps/publisher-v3.ui.customer/src/pages/TaskDetails/hooks/useReplaceDocument';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface IProps {
  contentType: DocumentType;
  methods: UseFormReturn<any>;
  taskId: string;
  submissionId: string;
  existingDocuments: IDocumentDto[] | undefined;
  customerLink: string | undefined;
  documentAttributes: IDocumentAttributes;
  documentProcessingFlow: DocumentProcessingFlow | undefined;
}

export const useReplaceDocumentModal = ({
  contentType,
  methods: { setValue, reset, getValues, handleSubmit },
  submissionId,
  taskId = '',
  existingDocuments,
  customerLink,
  documentAttributes,
  documentProcessingFlow,
}: IProps) => {
  const handleRemove = () => {
    setValue(FormFields.file, undefined);
    abortUpload();
    if (!isLoading) {
      deleteFile(fileName);
    }
  };

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors } = useUploadFile(handleRemove);

  const secondaryDocument = useAddSecondaryDocumentOnModal({ contentType, setValue });

  const { goToUpdateTaskDocumentContentDetails } = useAppNavigation();
  const { deleteFile } = useDeleteFile();

  const { replaceDocument } = useReplaceDocument(
    parseInt(taskId as string),
    fileName,
    getValues(FormFields.file)?.name,
    getValues(FormFields.webLink),
    getValues(FormFields.content),
    getValues(FormFields.includeSecondaryDocument)!,
    getValues(FormFields.secondaryfile)?.name,
    secondaryDocument.fileName,
    documentAttributes,
  );

  const [isModalOpen, setIsModalOpen] = useState<AddModalOpenModes>(null);

  const handleFileSubmit = (files: FileList | null) => {
    const fileRole = documentProcessingFlow === DocumentProcessingFlow.qrdPil ? FileRoles.PrimaryDocFile : undefined;
    const file = files?.item(0);
    if (file) {
      const errors = getIsFileValid(contentType, file, fileRole);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(FormFields.file, file);
        uploadFile({ file, documentType: contentType, fileRole });
      }
    }
  };

  const handleUploadClick = () => {
    const errors = getCanUpload(
      contentType,
      getValues(FormFields.documentTitle),
      documentAttributes,
      getValues(FormFields.noAudioVideoUrl),
      getValues(FormFields.file),
      getValues(FormFields.webLink),
      getValues(FormFields.hasDigitalVersion),
      getValues(FormFields.isCustomerApprovalRequired),
      getValues(FormFields.content),
      getValues(FormFields.includeSecondaryDocument),
      getValues(FormFields.secondaryfile),
      documentProcessingFlow,
    );

    if (errors.length > 0) setFileErrors(errors);
    else {
      handleSubmit(() =>
        replaceDocument(
          {
            title: getValues(FormFields.documentTitle),
            includeSecondaryDocument: getValues(FormFields.includeSecondaryDocument),
          },
          {
            onSuccess: (data) => {
              goToUpdateTaskDocumentContentDetails(submissionId!, contentType, data.documentId, taskId);
            },
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(null);
    reset({});
    abortUpload();
  };

  const clearErrors = () => {
    setFileErrors([]);
    secondaryDocument.setFileErrors([]);
  };

  const handleOpenModal = (productGroupName: string, documentId?: number) => {
    const selectedDocument = existingDocuments?.find((d) => d.documentId === Number(documentId));
    const documentTitle = selectedDocument?.documentTitle ?? productGroupName;
    const documentHtmlContent = selectedDocument?.htmlContent;

    reset((fieldValues: any) => ({
      ...fieldValues,
      [FormFields.documentId]: documentId,
      [FormFields.documentTitle]: documentTitle,
      [FormFields.content]: documentHtmlContent,
      [FormFields.webLink]: removeHttpPrefixFromLink(customerLink) || '',
    }));
    setIsModalOpen('Update');
  };

  const allErrors = fileErrors.concat(secondaryDocument.fileErrors);

  const allIsLoading = isLoading || secondaryDocument.isLoading;

  return {
    handleFileSubmit,
    handleUploadClick,
    handleCancel,
    handleRemove,
    handleOpenModal,
    clearErrors,
    fileErrors: allErrors,
    isModalOpen,
    isLoading: allIsLoading,
    uploadProgress,
    secondaryDocument,
  };
};
