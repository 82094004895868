import React from 'react';
import { redirect } from 'react-router-dom';

import { MsalAuthenticationResult } from '@azure/msal-react';
import { globalRoutes } from '../../routing/routes';

export const MsalError = ({ error }: MsalAuthenticationResult) => {
  const userCancelledEnteringSelfAssertion = 'AADB2C90091';

  if (error?.errorMessage && error?.errorMessage.indexOf(userCancelledEnteringSelfAssertion) > -1) {
    redirect(globalRoutes.dashboard);
    return null;
  }
  /* eslint-disable */
  console.log('error', error);
  /* eslint-enable */
  return <span>Something went wrong. Please refresh page or try again after some time.</span>;
};
