import React from 'react';
import { useGetToken } from '@common/auth/msal';
import DefaultRoutes from 'apps/publisher-v3.ui.customer/src/routing/DefaultRoutes';
import MainContainer from './containers/MainContainer/MainContainer';
import { Toast } from '@common/components/Toast/Toast';
import { useIsAuthenticated } from '@azure/msal-react';
import { AuthGuard } from '@auth/AuthGuard';
import { UnsavedChangesContextProvider } from '@common/context/UnsavedChangesContext';
import { useAppSelector } from '@common/hooks/redux';
import { selectShowPleaseWaitOverlay } from '@common/features/global/selectors';
import { PleaseWaitOverlay } from '@common/components/PleaseWaitOverlay';
import { useDelayedLoader } from '@common/components/PleaseWaitOverlay/useDelayedLoader';
import { selectActiveCompany, selectHasUserInsufficientPermissions, selectIsLoading } from '@common/features/user/selectors';
import { Watermark } from '@common/components/Watermark/watermark';
import axios from 'axios';
import { invokeDataLayer } from '@common/services/dataLayerService';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MaintenanceInfo } from '@common/components/Maintenance/MaintenanceInfo';
import { selectConfiguration } from '@common/features/configuration/selectors';
import { InsufficientPermissionsInfo } from '@common/components/InsufficientPermissions/InsufficientPermissionsInfo';
import { WithQueryClient } from './WithQueryClient';
import { useFetchDocumentConversionResults } from '@common/hooks/useFetchDocumentConversionResults';

function App() {
  useGetToken();
  invokeDataLayer();

  const configuration = useAppSelector(selectConfiguration);
  const showMaintenancePage = configuration.featureFlags?.showMaintenancePage;
  const maintenanceMessage = configuration.maintenanceMessage;
  const isAuthenticated = useIsAuthenticated();
  const showPleaseWaitOverlay = useAppSelector(selectShowPleaseWaitOverlay);
  const isLoading = useAppSelector(selectIsLoading);
  const companyId = useAppSelector(selectActiveCompany)?.id;

  useFetchDocumentConversionResults(!!companyId);

  axios.defaults.headers.common['Company-Id'] = companyId;
  const { showDelayedLoader } = useDelayedLoader(showPleaseWaitOverlay);
  const showReactQueryDevtools = process.env.REACT_APP_SHOW_REACT_QUERY_DEVTOOLS === 'TRUE';

  const hasUserInsufficientPermissions = useAppSelector(selectHasUserInsufficientPermissions);

  if (hasUserInsufficientPermissions) {
    return <InsufficientPermissionsInfo />;
  }

  return (
    <>
      {showReactQueryDevtools && <ReactQueryDevtools />}
      <AuthGuard>
        {isLoading ? (
          <PleaseWaitOverlay />
        ) : (
          <>
            <Watermark>{process.env.REACT_APP_PLATFORMNAME}</Watermark>
            {companyId && isAuthenticated && showMaintenancePage && <MaintenanceInfo maintenanceMessage={maintenanceMessage} />}
            {companyId && isAuthenticated && !showMaintenancePage && (
              <UnsavedChangesContextProvider>
                <MainContainer>
                  <DefaultRoutes />
                </MainContainer>
              </UnsavedChangesContextProvider>
            )}
          </>
        )}
      </AuthGuard>
      <Toast />
      {showDelayedLoader && <PleaseWaitOverlay />}
    </>
  );
}

export default WithQueryClient(App);
