import { DocumentType, IDocumentWithTask } from '@common/features/submission/types';
import { useFetchDocumentConversionErrors } from '@common/hooks/useFetchDocumentConversionErrors';
import { useState } from 'react';

export const useDocumentConversionErrorsModal = (contentType: DocumentType, allDocumentsWithTasks: IDocumentWithTask[]) => {
  const { documentConversionErrors, setTaskIdForConversionErrors, isFetchingDocumentConversionErrors } =
    useFetchDocumentConversionErrors();

  const [isOpen, setIsOpen] = useState(false);

  const openDocumentConversionErrorModal = (taskId: number) => {
    setTaskIdForConversionErrors(taskId, contentType, allDocumentsWithTasks);
    setIsOpen(true);
  };

  const closeDocumentConversionErrorModal = () => {
    setIsOpen(false);
  };

  return {
    isFetchingDocumentConversionErrors,
    documentConversionErrors,
    isDocumentConversionErrorsModalOpen: isOpen,
    openDocumentConversionErrorModal,
    closeDocumentConversionErrorModal,
  };
};
